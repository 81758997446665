import React, { useEffect } from "react";
import { useTown } from "../../../context/TownContext";
import Layout1and2 from "./LayoutByVariant/Layout1and2/Layout1and2";
import Layout3 from "./LayoutByVariant/Layout3/Layout3";
import Layout4 from "./LayoutByVariant/Layout4/Layout4";
import Layout5 from "./LayoutByVariant/Layout5/Layout5";
import LayoutDefault from "./LayoutByVariant/LayoutDefault/LayoutDefault";

const Layouts = ({
  adTargets,
  videoAd,
  localNews,
  defaultTemplate,
  townForSection,
  section,
  subsection,
  sponsorInfo,
  hasLockedBullseyeAd,
}) => {
  const { topStoriesTemplate } = useTown();

  if (defaultTemplate) {
    return (
      <LayoutDefault
        adTargets={adTargets}
        videoAd={videoAd}
        localNews={localNews}
        townForSection={townForSection}
        section={section}
        subsection={subsection}
        sponsorInfo={sponsorInfo}
        hasLockedBullseyeAd={hasLockedBullseyeAd}
      />
    );
  }
  if (topStoriesTemplate) {
    switch (topStoriesTemplate) {
      case "default":
        return (
          <LayoutDefault
            hasLockedBullseyeAd={hasLockedBullseyeAd}
            adTargets={adTargets}
            videoAd={videoAd}
            localNews={localNews}
          />
        );
      case "layout_1":
      case "layout_2":
        return (
          <Layout1and2
            hasLockedBullseyeAd={hasLockedBullseyeAd}
            adTargets={adTargets}
            videoAd={videoAd}
            localNews={localNews}
          />
        );
      case "layout_3":
        return (
          <Layout3
            hasLockedBullseyeAd={hasLockedBullseyeAd}
            adTargets={adTargets}
            videoAd={videoAd}
            localNews={localNews}
          />
        );
      case "layout_4":
        return (
          <Layout4
            hasLockedBullseyeAd={hasLockedBullseyeAd}
            adTargets={adTargets}
            videoAd={videoAd}
            localNews={localNews}
          />
        );
      case "layout_5":
        return (
          <Layout5
            hasLockedBullseyeAd={hasLockedBullseyeAd}
            adTargets={adTargets}
            videoAd={videoAd}
            localNews={localNews}
          />
        );
      default:
        return <></>;
    }
  }
  return <></>;
};

export default Layouts;
