import React, { useEffect, useState } from "react";
import { bool, object, string } from "prop-types";
import clsx from "clsx";
import labels from "../../../../../config/labels";
import { useStyles } from "./MasterheadDesktop.style";
import MasterheadLogo from "../MasterheadLogo";
import PreviewBanner from "../PreviewBanner/PreviewBanner";
import withConfig from "../../../../../utils/withConfig";
import { useTownTheme } from "../../../../../context/TownThemeContext";
import Grid from "../../../../../common/Grid/Grid";
import Link from "../../../../../common/Link/Link";
import { screenWidthIsLowerThan } from "../../../../../hooks/useMediaQuery";
import values from "../../../../../common/Theme/themes/breakpoints/values";
import { useMastheadContext } from "../../../../../context/MastheadContext";
import { Weather } from "../../../../Weather/Weather";
import LoginButton from "./DefaultButtons/LoginButton/LoginButton";
import SearchField from "../../../../../common/SearchField/SearchField";
import { handleSearch } from "../../../../../utils/helper";
import NewsletterSignupButton from "./DefaultButtons/NewsletterSignupButton/NewsletterSignupButton";
import SquareFacebookBlack from "../../../../../common/Icons/Social/SquareFacebookBlack";
import SquareTwitterBlack from "../../../../../common/Icons/Social/SquareTwitterBlack";
import SquareInstagramBlack from "../../../../../common/Icons/Social/SquareInstagramBlack";
import SquareYoutubeBlack from "../../../../../common/Icons/Social/SquareYoutubeBlack";
import SquareLinkedinBlack from "../../../../../common/Icons/Social/SquareLinkedinBlack";
import SquareBlueskyBlack from "../../../../../common/Icons/Social/SquareBlueskyBlack";
import SquareTiktokBlack from "../../../../../common/Icons/Social/SquareTiktokBlack";

const GRAY = "#9C9C9C";
const DARKGRAY = "#191919";

const MasterheadDesktop = ({
  isArticlePreview,
  legalNoticePermissions,
  isPathUserSignIn,
  townSlug,
  section,
  currentUser,
}) => {
  const [newsletterSignupButton, setNewsletterSignupButton] = useState();
  const [searchText, setSearchText] = useState("");
  const classes = useStyles();
  const { town, signUpPath, firstBlockButtons } = useTownTheme();
  const { mastheadInfo, adminMenuItems, townsWithClusters } =
    useMastheadContext();
  const adminLinkLevel = screenWidthIsLowerThan(values.lg)
    ? "t4_text_4"
    : "paragraph_1";

  const loginButton = {
    label_color: "black",
    label: "Login",
  };

  const handleSearchOnEnter = (key) => {
    if (key === "Enter" || key === "13") {
      handleSearch(town, searchText);
    }
  };

  useEffect(() => {
    const newsletterButton = firstBlockButtons.find(
      (button) =>
        button.action.includes("signup") ||
        button.action.includes("subscription")
    );
    if (newsletterButton) {
      newsletterButton.label = "Newsletter";
      setNewsletterSignupButton(newsletterButton);
    }

    return () => setNewsletterSignupButton();
  }, [firstBlockButtons]);

  return (
    <>
      {isArticlePreview && <PreviewBanner />}

      <Grid
        style={{
          backgroundColor: "white",
        }}
        className={clsx(
          classes.masterhead_desktop_container,
          classes.no_printing,
          classes.licenses_menu_container
        )}
        justifyContent="space-evenly"
        container
        xs={12}
        item
      >
        <Grid xs={12} container item justifyContent="space-between">
          <Grid xs={3} item>
            <Weather
              townSlug={town?.slug}
              sectionSlug={section?.accu_weather_name ? section?.slug : null}
              clickable={true}
              labelColorWeather={DARKGRAY}
            />
          </Grid>
          <Grid
            xs={8}
            container
            item
            justifyContent="flex-end"
            className={classes.right_buttons_container}
          >
            {(town?.facebook_url ||
              town?.instagram_url ||
              town?.twitter_url ||
              town?.youtube_url ||
              town?.linkedin_url ||
              town?.bluesky_url ||
              town?.tiktok_url) && (
              <>
                <div
                  className={classes.social_media_container}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {town?.facebook_url && (
                    <Link
                      url={town?.facebook_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareFacebookBlack />
                    </Link>
                  )}
                  {town?.twitter_url && (
                    <Link
                      url={town?.twitter_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareTwitterBlack />
                    </Link>
                  )}
                  {town?.instagram_url && (
                    <Link
                      url={town?.instagram_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareInstagramBlack />
                    </Link>
                  )}
                  {town?.youtube_url && (
                    <Link
                      url={town?.youtube_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareYoutubeBlack />
                    </Link>
                  )}
                  {town?.linkedin_url && (
                    <Link
                      url={town?.linkedin_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareLinkedinBlack />
                    </Link>
                  )}
                  {town?.bluesky_url && (
                    <Link
                      url={town?.bluesky_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareBlueskyBlack />
                    </Link>
                  )}
                  {town?.tiktok_url && (
                    <Link
                      url={town?.tiktok_url}
                      target="_blank"
                      className={classes.social_media_icon}
                    >
                      <SquareTiktokBlack />
                    </Link>
                  )}
                </div>
                <div
                  style={{ color: GRAY, display: "flex", alignItems: "center" }}
                >
                  |
                </div>
              </>
            )}
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 16,
              }}
            >
              {adminMenuItems?.map((item, index) => (
                <Grid
                  key={`admin-menu-item-${index}`}
                  item
                  className={classes.admin_link}
                >
                  <Link
                    {...(item.code != "drafts" && { url: item.path })}
                    {...(item.code === "drafts" && {
                      onClick: () => setOpenSavedDraftsPopUp(true),
                    })}
                    children={item.label}
                    color="black"
                    level={adminLinkLevel}
                    underlined
                  />
                </Grid>
              ))}
              {currentUser === null && (
                <LoginButton
                  button={loginButton}
                  townsWithClusters={townsWithClusters}
                  className={classes.custom_button_login}
                />
              )}
              {currentUser !== null && currentUser?.email && (
                <div className={classes.logged_container}>
                  <Link
                    url={withConfig("SIGN_OUT")}
                    children={labels.LOGOUT}
                    color="black"
                    level={adminLinkLevel}
                    underlined
                  />
                </div>
              )}
            </Grid>
            {newsletterSignupButton && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <NewsletterSignupButton
                  button={newsletterSignupButton}
                  signUpPath={signUpPath}
                  className={classes.signup}
                />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid xs={12} container item>
          <Grid
            item
            className={clsx(classes.grid_item, classes.logo_container)}
          >
            <MasterheadLogo
              envUrl={withConfig("HOME")}
              townName={town?.name || section?.name}
              logoUrl={mastheadInfo?.logo_image}
              townSlug={town?.slug}
              sectionSlug={section?.slug}
              color={"black"}
              displayLogo={"flex-start"}
              displayLabelSlogan={"left"}
            />
            <SearchField
              placeholder={labels.SEARCH}
              onChange={setSearchText}
              startIcon
              startIconFill={GRAY}
              onKeyPress={handleSearchOnEnter}
              classes={{
                text_field: classes.search_text_field,
                start_icon: classes.textfield_search_icon,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

MasterheadDesktop.propTypes = {
  isArticlePreview: bool,
  legalNoticePermissions: object,
  isPathUserSignIn: bool,
  townSlug: string,
  section: object,
  currentUser: object,
};

export default MasterheadDesktop;
