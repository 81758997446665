import React from "react";
import { useStyles } from "./MiddleSection.style";
import { useFooterManagement } from "../../../context/FooterContext";
import Link from "../../../common/Link/Link";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import SquareFacebook from "../../../common/Icons/Social/SquareFacebook";
import SquareTwitter from "../../../common/Icons/Social/SquareTwitter";
import SquareInstagram from "../../../common/Icons/Social/SquareInstagram";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import clsx from "clsx";
import SubscribeForm from "../SubscribeForm/SubscribeForm";
import MasterheadLogo from "../../Masterhead/LayoutsByVariant/Default/MasterheadLogo";
import withConfig from "../../../utils/withConfig";
import RssFeed from "../../../common/Icons/Social/RssFeed";
import SquareYoutube from "../../../common/Icons/Social/SquareYoutube";
import SquareLinkedin from "../../../common/Icons/Social/SquareLinkedin";
import SquareBluesky from "../../../common/Icons/Social/SquareBluesky";
import SquareTiktok from "../../../common/Icons/Social/SquareTiktok";

const LAYOUT_TWO = "layout_two";

const MiddleSection = ({ mastheadImage }) => {
  const classes = useStyles();
  const {
    firstBlock,
    secondBlock,
    thirdBlock,
    fourthBlock,
    footer,
    town,
    footerLayout,
  } = useFooterManagement();

  const BlockContainer = ({ blockNumber, blockLinks }) => {
    return (
      <>
        {blockLinks.length > 0 && (
          <>
            <div className={classes.block_container}>
              {blockLinks.map((link, index) => {
                return (
                  <Link
                    url={link.url}
                    level="t4_text_4"
                    color="white"
                    key={`${blockNumber}-block-${link.name}-${index}`}
                    className={clsx(
                      classes.block_link,
                      blockNumber === "first" ? classes.first_block_link : ""
                    )}
                    bold={blockNumber === "first"}
                    target="_top"
                  >
                    {link.label}
                  </Link>
                );
              })}
            </div>
            {screenWidthIsLowerThan(900) && (
              <div className={classes.divider_container}>
                <HorizontalDivider className={classes.divider} />
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className={classes.container}>
      {footerLayout !== LAYOUT_TWO && !screenWidthIsLowerThan(1150) && (
        <div className={classes.subscriber_block}>
          {footer?.display_sign_up_form && <SubscribeForm />}

          {!footer?.display_sign_up_form && !town?.under_license_contract && (
            <MasterheadLogo
              envUrl={withConfig("HOME")}
              townName={town?.name}
              townSlug={town?.slug}
            />
          )}

          {!footer?.display_sign_up_form &&
            town?.under_license_contract &&
            mastheadImage && (
              <div className={classes.license_logo_container}>
                <img
                  src={mastheadImage}
                  alt={`${town?.name} logo`}
                  className={classes.logo_license}
                />
              </div>
            )}
        </div>
      )}
      <BlockContainer blockNumber="first" blockLinks={firstBlock} />
      <BlockContainer blockNumber="second" blockLinks={secondBlock} />
      <BlockContainer blockNumber="third" blockLinks={thirdBlock} />
      <BlockContainer blockNumber="fourth" blockLinks={fourthBlock} />
      {footer?.display_social_media && (
        <div className={classes.block_container}>
          <Typography
            color="white"
            bold
            level="t4_text_4"
            className={classes.follow_us}
          >
            {labels.FOLLOW_US}
          </Typography>
          <div className={classes.social_media_container}>
            {(town?.facebook_url || footer?.social_links?.facebook_url) && (
              <Link
                url={town?.facebook_url || footer?.social_links?.facebook_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <SquareFacebook />
              </Link>
            )}
            {(town?.twitter_url || footer?.social_links?.twitter_url) && (
              <Link
                url={town?.twitter_url || footer?.social_links?.twitter_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <SquareTwitter />
              </Link>
            )}
            {(town?.instagram_url || footer?.social_links?.instagram_url) && (
              <Link
                url={town?.instagram_url || footer?.social_links?.instagram_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <SquareInstagram />
              </Link>
            )}
            {(town?.youtube_url || footer?.social_links?.youtube_url) && (
              <Link
                url={town?.youtube_url || footer?.social_links?.youtube_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <SquareYoutube />
              </Link>
            )}
            {(town?.linkedin_url || footer?.social_links?.linkedin_url) && (
              <Link
                url={town?.linkedin_url || footer?.social_links?.linkedin_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <SquareLinkedin />
              </Link>
            )}
            {(town?.bluesky_url || footer?.social_links?.bluesky_url) && (
              <Link
                url={town?.bluesky_url || footer?.social_links?.bluesky_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <SquareBluesky />
              </Link>
            )}
            {(town?.tiktok_url || footer?.social_links?.tiktok_url) && (
              <Link
                url={town?.tiktok_url || footer?.social_links?.tiktok_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <SquareTiktok />
              </Link>
            )}
            {footer?.social_links?.atom_url && (
              <Link
                url={footer?.social_links?.atom_url}
                target="_blank"
                className={classes.social_media_icon}
              >
                <RssFeed fillColor="white" className={classes.rss_icon} />
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

MiddleSection.propTypes = {};

export default MiddleSection;
