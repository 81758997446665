import React from "react";
import { ICONS_SIZES } from "../Weather";
import PropTypes from "prop-types";

const SquareFacebook = ({ size = "lg" }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "auto", height: iconSize }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5625 11.1769C17.9687 11.1769 18.4062 11.1769 18.8125 11.1769C18.875 11.1769 18.9063 11.1769 19 11.1769V8.89785C18.7813 8.86529 18.5313 8.83273 18.3125 8.83273C17.875 8.80017 17.4375 8.80017 17.0312 8.80017C16.375 8.80017 15.75 8.99552 15.2187 9.38622C14.5937 9.84203 14.25 10.4932 14.125 11.2746C14.0625 11.6002 14.0625 11.9258 14.0625 12.2513C14.0625 12.7723 14.0625 13.2606 14.0625 13.7816V13.9769H12V16.4839H14.0625V22.8002H16.5937V16.4839H18.6562C18.75 15.6374 18.875 14.8234 18.9687 13.9444C18.8125 13.9444 18.6562 13.9444 18.5 13.9444C17.9062 13.9444 16.5625 13.9444 16.5625 13.9444C16.5625 13.9444 16.5625 12.7071 16.5937 12.1537C16.5625 11.4048 17 11.1769 17.5625 11.1769Z"
        fill="black"
      />
    </svg>
  );
};

export default SquareFacebook;

SquareFacebook.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
